import React from 'react';
import {Link} from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiInstagram, FiLinkedin, FiPhoneCall, FiMail, FiMapPin } from "react-icons/fi";


const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFive =  footerOne[5];


const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);


const FooterTwo = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemOne.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link footer-text link-hover">
                                            {indexOneLink.map((data, index) => (
                                                <li key={index}>{data.text}</li>
                                            ))}
                                            <div className="shopify-experts">
                                                <img src="./images/footer/shopify-experts.svg" className="img-fluid mt-5" alt="Shopify Experts" title="Shopify Experts" />
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}                      

                            {/* Start Single Widget  */}
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemTwo.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexTwoLink.map((data, index) => (
                                                <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                            ))}
                                            <div className="shopify-plus">
                                                <img src="./images/footer/shopify-plus.webp" className="img-fluid mt-5" alt="Shopify Plus" title="Shopify Plus" />
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Our Services */}
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemThree.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexThreeLink.map((data, index) => (
                                                <li key={index}>{data.text}</li>
                                            ))}
                                            
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Our Services */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemFive.title}</h4>
                                    <div className="inner">
                                        {/* <h6 className="subtitle">{footerIntemFive.subtitle}</h6> */}
                                        <ul className="footer-link footer-text">
                                            <li><FiPhoneCall /> <a href="tel:9646025319">+91 964 602 5319</a></li>
                                            <li><FiMail /> <a href="mailto:contact@techiepugs.com">contact@techiepugs.com</a></li>
                                            <li><FiMapPin /> E-52, Second Floor, Phase-8, Industrial Area, Mohali, Punjab 160071</li>
                                            
                                        </ul>
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><a href="https://www.facebook.com/techiepugs" target="_blank" title="Facebook"><FiFacebook /></a></li>
                                            <li><a href="https://www.instagram.com/techiepugs" target="_blank" title="Instagram"><FiInstagram /></a></li>
                                            <li><a href="https://www.linkedin.com/company/techiepugs" target="_blank" title="Linkedin"><FiLinkedin /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}
                        </div>
                    </div>
                </div>
             </footer>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
