import React from "react";
import SEO from "../common/SEO";
import AboutFour from "../elements/about/AboutFour";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TeamOne from "../elements/team/TeamOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import Layout from "../common/Layout";

const AboutUs = () => {
  return (  
    <>
      <SEO title="About Us" />
      <Layout>
      
   
        {/* Start Slider Area  */}
        <div className="main-content">
          <div className="rwt-elements-area">
            {/* End Slider Area  */}

              {/* start techiepugs about section */}
                <AboutFour image="./images/about/contact-image.jpg" />
              {/* end techiepugs about section */}
              <Separator />
            {/* Start we are beyond section  */}
            <div className="service-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="content">
                      <h3 className="title">We Are Beyond</h3>
                      <p className="mb--10">
                        Multum in Parvo, meaning “a lot in a little,” is the
                        official motto of the TechiePugs. Techiepugs is full of
                        smart, driven people who care about getting things done
                        perfectly and the relationships we build during this
                        process is our trophy.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="title">Digital Lovers</h3>
                    <p className="mb--10">
                      We have a passion for our clients’ true results and a
                      pragmatic drive for action that starts Monday morning 8am
                      and doesn’t let up. We rally clients with our infectious
                      energy, to make change stick.
                    </p>
                    <p className="mb--10">
                      And we never go it alone. We support and are supported to
                      develop our own personal results stories. We balance
                      challenging and co-creating with our clients, building the
                      internal capabilities required for them to create
                      repeatable results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* End we are beyond section  */}

            <Separator />

            {/* Start Service Area  */}
            <div className="rn-service-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="What we can do for you"
                      title="Services provide for you."
                      description="Personalized services crafted to meet your specific requirements and preferences"
                    />
                  </div>
                </div>
                <ServiceOne
                  serviceStyle="gallery-style service__style--1"
                  textAlign="text-left"
                />
              </div>
            </div>
            {/* End Service Area  */}

            <div className="rwt-timeline-area rn-section-gapBottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <TimelineTwo classVar="no-gradient" />
                  </div>
                </div>
              </div>
            </div>

            {/* <Separator /> */}

            {/* Start Team Area  */}
            {/* <div className="rwt-team-area rn-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Meet Our Amazing Team."
                      title="The Faces Behind Our Success."
                      description="We Couldn’t Do It Without Them"
                    />
                  </div>
                </div>
                <TeamOne column="col-lg-4 col-md-6 col-12 mt--30"
                  teamStyle="team-style-default"
                />
              </div>
            </div> */}
            {/* End Team Area  */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutUs;
