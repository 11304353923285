import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";

const TermAndConditions = ({ data }) => {
  return (
    <>
      <SEO title="Techiepugs || CSP App setup guide || 2022 " />
      <Layout>
        <div className="main-content">
          <div className="rwt-portfolio-details rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="inner">
                    <div className="portfolio-gallery mt--40">
                      <div className="gallery mt--40">
                        <div className="setup-text">
                        <h1 class="title theme-gradient h2">Terms and Conditions</h1>
                          <p className="description">
                            <strong>Techiepugs</strong> welcomes you to know more
                            about our terms & conditions. We believe in keeping
                            transparency and in order to let you know our terms
                            and conditions, all our terms and conditions are
                            given below.
                          </p>
                          <h4 className="subtitle">
                            Web Hosting Agreement And Terms Of Service :
                          </h4>
                          <p className="description">
                            {" "}
                            <strong>Techiepugs -</strong> Standard policies and
                            terms and conditions. Given policies and terms of
                            services are mandatory for signing up with us and
                            using our hosting services. Kindly make sure that
                            you read the policies carefully and agree to the
                            same before signing up. If you have any queries or
                            questions about the same, you may contact us at any
                            time. Techiepugs reserves the right to change the use
                            policies and terms at any time. We are serving a
                            huge community of clients and it has been always our
                            first priority to care about the security of each
                            and every client. Moreover, we try our best to
                            ensure the best possible services, so we have the
                            following guidelines: Techiepugs is not liable for a
                            refund in case we find any sort of infringement in
                            the respective account. We shall not entertain the
                            refund for such accounts under any circumstances.
                          </p>
                          <h4 className="subtitle">Spam Policy :</h4>
                          <p className="description">
                            <strong>Techiepugs</strong> carries the sole right to
                            change the structure of packages/plans and prices,
                            at any point in time. The information published on
                            our website regarding prices and services may be
                            changed at any point in time, without prior
                            intimation. Also, an increase in prices would be the
                            sole decision of Techiepugs. This would be effective
                            for the new deals but the orders placed prior to the
                            announcement would be treated on the previous slot.
                            Account setup: Your account would be set up after
                            the receipt of your payment is received and
                            confirmed by <strong>Techiepugs</strong> upon
                            completion of screening procedures. It is your
                            liability to give us proper information to verify
                            the same and if you don't do so, your account
                            creation will be delayed.
                          </p>
                          <h4 className="subtitle">
                            Proscribed use of services and products:
                          </h4>
                          <p className="description">
                            The mode to use our services is the sole decision of
                            the company, and all our clients and associates have
                            to follow the same, in any circumstance. Customer
                            may only use Services and Products in a manner that,
                            in the Company's sole judgment, is consistent with
                            the purposes of such Services and Products. If the
                            customer is unsure of whether any contemplated use
                            or action is permitted, please contact the Company
                            as provided above. By way of example, and not
                            limitation, uses described below of the Services and
                            Products are expressly prohibited.
                          </p>
                          <h4 className="subtitle">General:</h4>
                          <p className="description">
                            <strong>Illegal Activities: </strong>Broadcasting,
                            storage, or circulation of any information, data, or
                            material in breach of any relevant law or guideline,
                            or that might facilitate the breach of any
                            particular law or regulation is strictly forbidden.
                            We reserve the right to assist legal authorities or
                            the wounded party during the investigation of any
                            alleged crime.
                          </p>
                          <p className="description">
                            <strong>Injury or peril to other members: </strong>
                            None of our clients is allowed to use our services
                            or products to destruct, or attempt to destruct, in
                            whichever means, as well as not for pornography, or
                            send out any material, by any mode (e-mail,
                            uploading, redistribution or otherwise) that
                            terrorize or promote physical injury or obliteration
                            of belongings.
                          </p>
                          <p className="description">
                            <strong>
                              Copyright or trademark contravention:{" "}
                            </strong>
                            Clients can not use any of our service products to
                            broadcast any material (by e-mail, uploading,
                            posting, or otherwise) that violates any copyright,
                            patent, trademark, or trade surreptitious or other
                            proprietary civil liberties of any third party,
                            including, but not limited to, the unlawful copying
                            of copyrighted material and digitization and
                            circulation of images or photographs from any source
                            like magazines, books, or other copyrighted sources,
                            and the illegal allowance of copyrighted software.
                            Clients can not use services or products to
                            assemble, or try to assemble, private
                            information/details about third parties, devoid of
                            their knowledge or written consent. Circulation
                            and/or redistribution of copyrighted or the
                            aforesaid intrusion will not be endured.
                          </p>
                          <h4 className="subtitle"> Support:</h4>
                          <p className="description">
                            We provide support only through email: contact@techiepugs.com
                          </p>
                          <p className="description">
                            Support for the problems and issues in the control
                            panel would be provided to certain limitations
                            including basic functions like addition or deletion
                            of accounts, DNS functioning, change of password,
                            etc. Moreover, Techiepugs will not entertain any Free
                            Add-on scripts inclusions on the control panel,
                            matters related to software errors, or bugs that
                            come as a 3rd party member and have to be directly
                            reported to the software vendors for any kind of
                            support. Web development, 3rd party scripts, and
                            software such as installation, maintenance,
                            compatibility, and/or current day-to-day
                            administration tasks such as email accounts,
                            domains, and establishing new users are not the
                            fields of concern for Techiepugs  However,
                            Techiepugs aids the initial custom configuration or
                            set up of the server, which includes email accounts,
                            domains, establishing new users, and software
                            adjustments, at its own Techiepugs  (which
                            includes SSL Certificate, etc.) without charging any
                            additional amount.
                          </p>
                          <h4 className="subtitle">
                            Installation of 3rd Party software:
                          </h4>
                          <p className="description">
                            Users carry complete responsibility in case they
                            install 3rd party software in the web space. Tracing
                            of hacks or unusual activities due to less security
                            in software would result in the termination of the
                            account without prior notice. Specification And
                            Limitations Of Responsibilities Any damage including
                            accompanying or momentous damages, caused for
                            unavailability or being offline on our servers.
                            Techiepugs  shall not be responsible for any
                            claimed damages, including incidental and
                            consequential damages, which may arise from our
                            servers going offline or being unavailable for any
                            reason whatsoever. Techiepugs  is not
                            accountable for any claim compensation, counting
                            incidental or consequential compensation which might
                            be turned out from the sleaze or removal of any
                            website from any of our servers. Immediate
                            termination of services has to be the final or
                            maximum limitation in such cases.
                          </p>
                          <h4 className="subtitle">Copyright Policy:</h4>
                          <p className="description">
                            Techiepugs  have exclusive authorization to
                            shield all the information, material, services, and
                            contents by copyright law and other intellectual
                            property laws. Replications of any information,
                            material, services, and contents would be the
                            subject of legal action under the law of copyright.
                          </p>

                          <h4 className="subtitle"> Trademark:</h4>
                          <p className="description">
                            Logo and trademark and services, marks put on view
                            on this website for viewing are solely owned by
                            Techiepugs , and any personal or commercial
                            use of the same is strictly prohibited, without the
                            written permission of Techiepugs .
                          </p>

                          <h4 className="subtitle">Privacy Policy</h4>
                          <p className="description">
                            All the information provided by you would be used in
                            accordance with respective laws. The information and
                            data provided by you shall not be shared with any
                            external organization or for any other commercial
                            usage without your written consent. It would be
                            however available to all the divisions, sister
                            concerns, and ventures of Techiepugs . In
                            order to facilitate the utilization of the services
                            and maintain the continuity of a visit & help
                            navigation on the site, we make use of temporary
                            cookies. Updating, modification, and deletion of
                            your data information would be done on your written
                            request only.
                          </p>
                          <p className="description">

                          </p>

                          <h4 className="subtitle">
                            Payment and Refund Policy:
                          </h4>
                          <p className="description">
                            All the payments would be accepted on standard modes
                            set by Techiepugs . As we at Techiepugs
                             keep up high interactions with our clients
                            and carry out the entire process with their approval
                            thus there is no provision for any kind of full or
                            partial refund. We clearly mention here that the
                            paid amount would not be refunded in any
                            circumstances. Once an order is placed it cannot be
                            canceled as it is sent for processing immediately.
                            Your personal preferences changed in the course of
                            time, and cannot serve as a reason for refund or
                            charge back.</p>
                            <p>If Techiepugs  under special
                            consideration in certain cases decides to refund any
                            amount then it would assign 24 hrs for a shift of
                            the site, domain, etc. All the necessary usernames &
                            passwords for FTP / Domain etc. would be provided
                            for smooth shifting to the service provider or you
                            (if you decide to though we strongly recommend that
                            you go with a professional service provider). On
                            provision of this information, you would be solely
                            responsible for the login information as well as
                            content of the sites so provided.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TermAndConditions;
