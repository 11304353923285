import React, { useState, useRef } from "react";

const ContactForm = () => {
  const formRef = useRef(null);
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { fullname, email, number, subject, message, website } = e.target.elements;
    let details = {
      fullname: fullname.value,
      email: email.value,
      number: number.value,
      subject: subject.value,
      message: message.value,
      website: website.value,
    };
    let response = await fetch("https://techiepugs.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
       body: JSON.stringify(details),
    });
    console.log("Here data coming", response);
    setStatus("Submit");
    let result = await response.json();
    alert("Sent your mail");
    formRef.current.reset();
  };
  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <div className="form-group">
        <input type="text" id="fullname" name="fullname" placeholder="Your Name" required />
      </div>

      <div className="form-group">
        <input type="email" id="email" name="email" placeholder="Email Address" required />
      </div>

      <div className="form-group">
        <input type="text" id="number" name="number" placeholder="Phone Number" required />
      </div>

      <div className="form-group">
        <input type="text" id="subject" name="subject" placeholder="Subject" required />
      </div>

      <div className="form-group">
        <textarea id="message" name="message" placeholder="Your Message" required></textarea>
      </div>

      <div className="form-group">
        <input type="hidden" value="Techiepugs Website" id="website"/>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large" type="submit">{status}</button>
      </div>

    </form>
  );
};
export default ContactForm;
