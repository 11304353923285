import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'mangita-mam',
        name: 'Mangita Sharma',
        designation: 'CEO / Sr. Developer',
       
    },
    {
        image: 'md-sir',
        name: 'Mandeep Singh',
        designation: 'Sr. Web Developer / Team lead',     
      
    },
    {
        image: 'simran',
        name: 'Simran',
        designation: 'Support / Ninja Developer',       
    },
    {
        image: 'ashu',
        name: 'Ashu',
        designation: 'Web Developer',
        
    },
    {
        image: 'gaurav',
        name: 'Gaurav',
        designation: 'Web Developer',      
    },
    
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                  <img src={`./images/team/${data.image}.jpg`} alt="Our Team" title="Team Members" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <span className="location">{data.location}</span>
                                    </span>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
