
export const slickDot = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: true,
    responsive: [{
        breakpoint: 991,
        settings: {
            slidesToShow: 2,
            arrows: true,
        }
    },
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
            arrows: true,
        }
    },
    {
        breakpoint: 580,
        settings: {
            slidesToShow: 1,
            dots: true,

        }
    },
    {
        breakpoint: 481,
        settings: {
            slidesToShow: 1,
            dots: true,

        }
    }
]
};



export const BannerActivation = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
};

export const ClientsSlider = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
    responsive: [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 4,
        }
    },
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 4,
        }
    },
    {
        breakpoint: 575,
        settings: {
            slidesToShow: 3,
        }
    },
    {
        breakpoint: 320,
        settings: {
            slidesToShow: 2,
        }
    }
]
};

