import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">

                <div className="col-lg-8 col-md-8 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">We build awesome websites<br />
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "with fresh Ideas &",
                                            "Bold Design Concepts",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p className="web-paragraph">A good visual component and a pleasant user experience while using the product build brand 
                                    respect and help the company stand apart from other companies.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="web-thumbnail">
                           <a href="https://creativeframez.com/" target="_blank"><img className="w-100" src="./images/about/creative-framez.jpg" alt="creative-framez" title="Creative Framez" /></a>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 align-items-center">
                    <div className="col-lg-4 col-md-4">
                        <div className="web-thumbnail mt-5">
                           <a href="https://en.giesswein.com/" target="_blank"> <img className="w-100 " src="./images/about/giesswein.jpg" alt="giesswein" title="Giesswein" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="web-thumbnail mt-5">
                            <a href= "https://rainbowoptx.com/" target="_blank"><img className="w-100 " src="./images/about/Rainbow-optx.jpg" alt="Rainbow-optx" title="Rainbowoptx" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="web-thumbnail mt-5">
                            <a href="http://plumagetech.com/" target="_blank"> <img className="w-100 " src="./images/about/plumage-homepage.png" alt="plumagetech" title="Plumage Tech"/></a>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
