import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        description: "We feel so lucky to have found Mandeep! His work is just exceptional on every level. Fantastic communication, excellent problem solving skills, and fast task execution are just a few of his strengths. Also, he is just a pleasure to work with! We highly recommend him and will continue to work with him into the foreseeable future!.",
        name: "Julie",
        subtitle: "Chief Executive Officer",
        image: "nestingdays"
    },
    {
        description: "A big thanks to the Techiepugs development team for their excellent efforts. The entire credit goes to the experts at Techiepugs for handling my project in such a good way. These guys know how to turn ideas into reality and I am very happy that I chose Techiepugs ITES Pvt. Ltd. for designing and developing my company website.",
        name: "John Warrillow,",
        subtitle: "Project Manager",
        image: "testimonail1"
    },
    {
        // form: "Germany",
        description: "Mangita is excellent to work with and delivers above and beyond expectation! I would gladly work with her again very soon and would highly recommend to others.",
        name: "Robin",
        subtitle: "SEO Manager",
        image: "Male_Avatar"
    },
    {
        description: "Thanks for the great work, I’m delighted with the result. During the work process, they were very flexible and willing to try out various things according to my suggestions. I will sure hire them again!",
        name: "Rock Blanco",
        subtitle: "Social Media Executive",
        image: "client2"
    },
    {
        description: "We are immediately rehiring Mandeep as this is an ongoing job, but we just wanted to let everyone know how amazing we think he is and that we love working with him! he has been able to accomplish everything we need help with and he is just a pleasure to work with.",
        name: "Nepoli Dewan",
        subtitle: "Shopify Store Owner",
        image: "female-avatar"
    },
    {
        description: "Having a great result was what I have been looking for and that’s what I got from him. Easy to communicate with, very polite, and friendly. I will surely retake him for my future projects, thank you, and keep it up!",
        name: "Joe Wise",
        subtitle: "Project Manager",
        image: "client3"
    },
    {
        description: "Final product was perfect, just as we requested. Techiepugs have good taste in design. Communication was perfect and on time/responsive. 100% Recommended.",
        name: "Mallory Murphy",
        subtitle: "Head of Promotion Department",
        image: "client4"
    },
    {
        description: "Techiepugs developer understood the somewhat complex task very quickly and provided a solid coded solution to our satisfaction. She is highly professional with good communication and English. Thank you, Techiepugs!",
        name: "Jonathan",
        subtitle: "Software Engineer",
        image: "Male_Avatar"
    },


]
const TestimonialOne = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-md-1 col-lg-12 col-md-12">
                                    <div className="content mt_sm--40 client-testimonial">
                                    <div className="testimonial-wrap d-flex align-items-center">
                                        <div className="thumbnail testimonial-img">
                                          <img className="img-fluid" src={`./images/testimonial/${data.image}.jpg`} alt="Clients Review" />
                                        </div>
                                        <div className="ml-4 client-info">
                                          <h4 className="title">{data.name}</h4>
                                          <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>   
                                    <p className="description">{data.description}</p>
 
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>

        
    )
}
export default TestimonialOne;




















