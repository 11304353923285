import React from 'react';
import Slider from "react-slick";
import { ClientsSlider } from "../../utils/script";

const TestimonialData = [
    {
        image: "loog"
    },
    {
        image: "gravitas"
    },
    {
        image: "diamondback"
    },
    {
        image: "onebig"
    },
    {
        image: "nick"
    },
    {
        image: "hannun"
    },
    {
        image: "gohunt"
    },
    {
        image: "protect-styles"
    },
   
]
const TrustedClients = ({teamStyle}) => {
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...ClientsSlider}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">

                                 <div className="order-2 order-md-1 col-lg-8 col-md-12 col-sm-12 offset-lg-1">
                                   <img className="w-100" src={`./images/testimonial/${data.image}.png`} alt="Trusted Clients" />
                                 </div>
                            </div> 
                        </div>
                        
                    ))}
                </Slider>
            </div>
        </div>
        </div>
    )
}
export default TrustedClients;




















