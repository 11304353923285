import React from 'react';
import {Link} from "react-router-dom";

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                            <div className="about-img" >
                              <img src="./images/about/about.jpg" style={{borderRadius:"20px"}} alt="about" title="About" />
                            </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                    <div className="section-title">
                        <div className="content">
                            <div className=" inner">
                              <h4 className="subtitle">
                                <span className="theme-gradient">ABOUT US</span>
                                </h4>
                                <h4 className="subheading">Techiepugs ITES Pvt. Ltd. is Digital Agency based in Chandigarh, India.</h4>
                                 <p className="description">Techiepugs, our esteemed Development agency, comprises a team of dedicated Designers and Developers. We ardently work towards transforming ideas into reality, ensuring a superior user experience that significantly enhances our client’s business profitability.</p>
                                 <p className="description">Throughout the entire journey, from conceptualization to the development stage, Techiepugs maintains the highest standards of professionalism and quality in all our processes. With our unwavering commitment to excellence and a robust post-sales support system, we proudly provide the best web development services in the industry.</p>
                                <div className="about-btn mt--30">
                                    <Link className="btn-default" to="/contact"> Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour;
