import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">       
            <li><Link to="/about-us" title="About">ABOUT</Link></li>

            <li><Link to="/portfolio" title="Portfolio">PORTFOLIO</Link></li>

            <li><Link to="/csp-app-setup-guide" title="CSP App">CSP APP SETUP GUIDE</Link></li>

            <li><Link to="/contact" title="Contact">CONTACT</Link></li>
        </ul>
    )
}
export default Nav;
