import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight,  } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                <div className="col-lg-5">
                        <div className="thumbnail ">
                            <img className="w-100" src="./images/about/csp-app.png" alt="CSP App Store" title="CSP App Store" />
                        </div>
                    </div>
                <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Install our app</span></h4>
                                    <h2 className="title mt--10">CSP app is available for <br/>your shopify store.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>The CSP app by TechiePugs lets you to add color swatches, pattern image swatch or variant image as swatch on your shopify store.</p>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <a className="btn-default btn-icon" href="https://apps.shopify.com/collection-swatch-pug" target="_blank">Shopify App Store <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                   

                  
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
